import React, { useState, useContext } from "react";
import { styled, alpha, Box } from "@mui/system";
import MenuItem from "@mui/material/MenuItem";
import { Button, Menu, Checkbox } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

import { AppContext } from "../../context/AppContext";

// Define a custom MenuItem component
const CustomMenuItem = styled(MenuItem)(({ theme, isSelected }) => ({
  position: "relative",
  backgroundColor: "transparent", // Default background
  // backgroundColor: isSelected ? theme.palette.grey[300] : 'transparent',
  "&:hover": {
    backgroundColor: theme.palette.mode === "light" && theme.palette.grey[200],
  },
  "&:focus": {
    backgroundColor: theme.palette.mode === "light" && theme.palette.grey[300],
    "&:hover": {
      backgroundColor:
        theme.palette.mode === "light" && theme.palette.grey[200],
    },
  },
  "&:focusVisible, &:focusWithin, &:active": {
    backgroundColor: theme.palette.mode === "light" && theme.palette.grey[200], // Custom color when clicked
  },
  // Ensures no default styling is applied during click
  "&.Mui-focusVisible, &.Mui-selected, &.Mui-focusVisible.Mui-selected": {
    backgroundColor: theme.palette.mode === "light" && theme.palette.grey[300],
    outline: "none",
    boxShadow: "none",
  },

  // Disable transition effects that might cause delay
  transition: "background-color 0s",
  // Add a divider-like border after the last child using a pseudo-element
  "&::after": {
    content: '""',
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    height: "1px",

    backgroundColor: theme.palette.divider,
  },
}));

const StyledMenu = styled((props) => {
  const { selectedItems } = props;
  return (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  );
})(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    width: 260,

    color: "#000000",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "0.7rem 0",
    },
    "& .MuiMenuItem-root": {
      borderBottom: "0.08rem solid #d1d1d1",
      "& .MuiSvgIcon-root": {
        fontSize: 20,
        color: theme.palette.text.secondary,
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
    ...theme.applyStyles("dark", {
      color: theme.palette.grey[300],
    }),
  },
}));

const ACTDropdownWithCollapseMenuItemList = (props) => {
  const {
    totalRecords,
    handleSelectionChange,
    handleMenuClick,
    handleSubMenuClick,
    handleInnerMenuClick,
    menuItems,
    selectedItems,
    subMenuSelectedItems,
    selectedInnerMenu,
  } = props;
  const theme = useTheme();
  const appContext = useContext(AppContext);

  const { dashboardStates } = appContext;
  const labelName = dashboardStates["failed_reasons"];
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedId, setSelectedId] = useState();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Check if labelName is properly set and not coming from incorrect context
  let provideTitle;
  // Check if we are on the 'dev' or 'account' page
  const isDevOrAccountPage = window.location.pathname.includes("device") || window.location.pathname.includes("account");

  // If on the dev or account page, use a custom title or fallback logic
  if (isDevOrAccountPage) {
    provideTitle = selectedId?.code === 0
      ? "Approved Checks"
      : selectedId?.code === -2
        ? "All Checks"
        : "Failed Checks";
  } else {
    if (labelName) {
      // Use the provided labelName logic for other pages
      provideTitle = labelName.id === 0
        ? "Approved Checks"
        : labelName.id === -2
          ? "All Checks"
          : "Failed Checks";
    }
  }

  return (
    <>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="text"
        disableElevation
        disableRipple
        onClick={handleClick}
        sx={{
          margin: "1rem",
          width: "100%",
          maxWidth: "250px",
          display: "flex",
          justifyContent: "space-between",
          fontWeight: "400",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          color: theme.palette.text.primary,
          "&:hover": {
            backgroundColor: "transparent",
          },
          boxShadow: "#d1d1d1",
        }}
        endIcon={
          anchorEl ? (
            <ArrowDropUpIcon
              sx={{
                color: "#666666",
                height: "1.5rem",
              }}
            />
          ) : (
            <ArrowDropDownIcon
              sx={{
                color: "#666666",
                height: "1.5rem",
              }}
            />
          )
        }
      >
        {provideTitle ? provideTitle : "Select Checks"} ({totalRecords})
        {/* If its not All checks and Approved checks keep the label as Failed checks */}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {menuItems?.flatMap((item, index) => {
          const isSelected = item.code === selectedItems?.code;
          if (item.items && item.items.length === 1) {
            return (
              <MenuItem
                key={index}
                onClick={handleClose}
                disableRipple
                sx={{
                  backgroundColor: isSelected && theme.palette.grey[300],
                  "&:hover": {
                    backgroundColor: theme.palette.grey[200],
                  },
                }}
              >
                {item.title}
              </MenuItem>
            );
          } else {
            return [
              <MenuItem
                style={{ display: "flex", justifyContent: "space-between" }}
                key={`${index}-main`}
                onClick={() => {
                  setSelectedId(item)
                  handleMenuClick(item);
                  if (item.items && !item.items.length) handleClose();
                }}
                disableRipple
                sx={{
                  backgroundColor: isSelected && theme.palette.grey[300],
                  "&:hover": {
                    backgroundColor: theme.palette.grey[200],
                  },
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {item.title}
                {item.items && item.items.length > 0 ? (
                  item?.selected ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )
                ) : null}
              </MenuItem>,
              item.selected &&
              item.items && item.items.map((subMenu, subindex) => {
                const isSubMenuSelected = subMenu.code === subMenuSelectedItems?.code;
                if (subMenu.items && subMenu.items.length === 1) {
                  return (
                    <MenuItem
                      style={{ marginLeft: "1rem" }}
                      key={`${index}-${subindex}`}
                      onClick={() => {
                        handleSubMenuClick(subMenu);
                        handleClose();
                      }}
                      disableRipple
                      sx={{
                        marginLeft: "1rem",
                        backgroundColor:
                          isSubMenuSelected && theme.palette.grey[300],
                        "&:hover": {
                          backgroundColor: theme.palette.grey[200],
                        },
                      }}
                    >
                      {subMenu.title}
                    </MenuItem>
                  );
                } else {
                  return [
                    <MenuItem
                      style={{
                        paddingLeft: "1.2rem",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      key={`${index}-${subindex}-sub`}
                      onClick={() => {
                        handleSubMenuClick(subMenu);
                        if (!subMenu.items?.length) handleClose();
                      }}
                      disableRipple
                      sx={{
                        backgroundColor:
                          isSubMenuSelected && theme.palette.grey[300],
                        "&:hover": {
                          backgroundColor: theme.palette.grey[200],
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          backgroundColor:
                            isSubMenuSelected && theme.palette.grey[300],
                          "&:hover": {
                            backgroundColor: theme.palette.grey[200],
                          },
                        }}
                      >
                        {subMenu.items && subMenu.items.length === 0 && (
                          <Checkbox
                            checked={subMenu.selected}
                            onClick={(e) => e.stopPropagation()}
                            onChange={(e) =>
                              handleSelectionChange(subMenu.code, e.target.checked)
                            }
                          />
                        )}
                        {subMenu.title}
                      </Box>
                      {subMenu.items?.length > 0 ? (
                        subMenuSelectedItems?.title === subMenu.title &&
                          subMenuSelectedItems?.selected ? (
                          <ArrowDropUpIcon />
                        ) : (
                          <ArrowDropDownIcon />
                        )
                      ) : null}
                    </MenuItem>,
                    subMenuSelectedItems?.title === subMenu.title &&
                    subMenuSelectedItems?.selected &&
                    subMenu.items?.map((innerMenu, innerindex) => (
                      <MenuItem
                        key={`${index}-${subindex}-${innerindex}-inner`}
                        style={{
                          paddingLeft: "2rem",
                          backgroundColor:
                            selectedInnerMenu?.code === innerMenu.code &&
                            theme.palette.grey[300],
                        }}
                        onClick={() => handleInnerMenuClick(innerMenu)}
                        disableRipple
                      >
                        <Checkbox
                          onClick={(e) => e.stopPropagation()}
                          checked={innerMenu.selected}
                          onChange={(e) => handleSelectionChange(innerMenu.code, e.target.checked)}
                        />
                        {innerMenu.title}
                      </MenuItem>
                    )),
                  ];
                }
              }),
            ];
          }
        })}
      </StyledMenu>
    </>
  );
};

export default ACTDropdownWithCollapseMenuItemList;

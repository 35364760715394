import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./SearchModalStyles.scss";
import { AppContext } from "../../context/AppContext";
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_TIMEZONE_OFFSET,
  SEARCH_CRITERIA_LIST,
  SEARCH_S2T,
} from "../../constants/Constants";
import {
  getPreviousDateBasedOnTransactionPeriod,
  formatDateYYYYMMDD,
} from "../../utils/DateUtils";
import AdvancedSearch from "./AdvancedSearch";
import {
  generateRandomFiveNumber,
  getLocatedCodeByName,
  getReasonForFailWithAllOption,
} from "../../utils/CommonUtils";
import { REPORT_S2T } from "../../constants/APIConstants";
import { useTheme } from "@emotion/react";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  border: "none",
  boxShadow: "none",
  pt: 0,
  px: 0,
  pb: 0,
};

const currenDate = formatDateYYYYMMDD(new Date());

const transactionPeriodDays = {
  "7 days": 7,
  "One month": 30,
  "Three months": 90,
  "Custom dates": 0,
};

const locatedByItems = [
  { id: 0, label: "GPS", key: "GPS", selected: false },
  { id: 1, label: "WIFI", key: "WIFI", selected: false },
  { id: 2, label: "Cellular", key: "Cellular", selected: false },
  { id: 3, label: "IP address", key: "IpAddress", selected: false },
  { id: -1, label: "All", key: "all", selected: false },
];

const customerLocatedByFlags = {
  GPS: false,
  WIFI: false,
  Cellular: false,
  IpAddress: false,
  All: false,
};

// device dropdown data
const deviceTypes = [
  { id: 0, label: "Android", key: "Android", selected: true },
  { id: 1, label: "iOS", key: "iOS", selected: true },
  { id: 2, label: "Web", key: "Web", selected: true }
];
const devicePlaceholder = ["Choose device"];

// transaction period dropdown data
const transactionPeriod = [
  "7 days",
  "One month",
  "Three months",
  "Custom dates",
];

const transactionPlaceholder = ["Choose time period"];

const UDIDPlaceholder = ["UDID (optional)"];

export default function NestedModal(props) {
  const [devicetypes, setDeviceType] = useState(deviceTypes);
  const advancedSearchParam = {
    reportId: SEARCH_S2T,
    appId: "",
    GeoReferenceId: "",
    AccountId: "",
    DeviceId: "",
    Latitude: "",
    Longitude: "",
    IpAddress: "",
    ExactMatch: false,
    PageNumber: 1,
    PageSize: DEFAULT_PAGE_SIZE,
    DeviceType: "Android, iOS, Web",
    GeoLookUpType: "",
    ReasonForFailure: "",
    startDate: "",
    endDate: "",
    timezoneOffset: DEFAULT_TIMEZONE_OFFSET,
    JWTid: "",
  };
  const { openModal, closeModal, searchItem } = props;
  const appContext = useContext(AppContext);
  const { getUserReportByAPI } = appContext;
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [advancedSearchData, setAdvancedSearchData] =
    useState(advancedSearchParam);
  const [allFlag, setAllFlag] = useState(locatedByItems);
  const [reasonForFailureFlags, setreasonForFailureFlags] = useState(null);
  const [clearInputstate, setclearInputstate] = useState(false);
  const [isTransDateSelected, setIsTransDateSelected] = useState(false);
  const theme = useTheme();
  useEffect(() => {
    setreasonForFailureFlags(
      getReasonForFailWithAllOption(appContext.failedReasons)
    );
  }, [appContext.failedReasons]);
  useEffect(() => {
    if (!searchItem) {
      setAdvancedSearchData(advancedSearchParam);
    } else {
      if (searchItem.key === "LatLng") {
        const location = searchItem.value.split(",");
        if (location.length > 1) {
          const searchParms = searchItem
            ? {
              ...advancedSearchParam,
              Latitude: location[0].trim(),
              Longitude: location[1].trim(),
            }
            : advancedSearchParam;
          setAdvancedSearchData(searchParms);
        }
      } else {
        const searchParms = searchItem
          ? { ...advancedSearchParam, [searchItem.key]: searchItem.value }
          : advancedSearchParam;
        setAdvancedSearchData(searchParms);
      }
    }
  }, [searchItem]);

  useEffect(() => {
    setOpen(openModal);
  }, [openModal]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    // setInputSearchBox("")
    // setDropDownValue("GeoReferenceId")
    // setBasicSearch(basicSearchParam);
    if (closeModal) {
      closeModal();
    }
  };

  function toggleAdvanced() {
    if (open === true) {
      setOpen(false);
    } else if (open === false) {
      setOpen(true);
    }
    if (closeModal) {
      closeModal();
    }
    clearAll();
  }

  /** function to show transaction period date pickers */
  const [isShowDatePickers, setIsShowDatePickers] = useState(false);
  const onDateSelect = (value) => {
    /**Logic to get start date based on transaction period */
    let defaultTransactionPeriodDays = transactionPeriodDays;
    let userDefinedTransactionPeriod = defaultTransactionPeriodDays[value];
    let res = getPreviousDateBasedOnTransactionPeriod(
      userDefinedTransactionPeriod
    );

    setIsTransDateSelected(true);

    setAdvancedSearchData({
      ...advancedSearchData,
      ["startDate"]: res,
      ["endDate"]: currenDate,
      TransPeriod: value,
    });

    if (value === "Custom dates") {
      setIsShowDatePickers(true);
    } else {
      setIsShowDatePickers(false);
    }
  };

  /**function to update the states of transaction custom dates */
  const transactionPeriodStartDate = (value, type) => {
    if (type === "startDate" || type === "endDate") {
      setIsShowDatePickers(true);
      setAdvancedSearchData({ ...advancedSearchData, [type]: value });
    }
  };

  const advancedSearchFormValues = (value, type, checked) => {
    setclearInputstate(false);

    if (type === "GeoLookUpType") {
      updateLocatedByState(value, checked);
    } else {
      updateReasonForFailState(value, checked);
    }

    if (type === "DeviceType") {
      updatedDeviceTypes(value, checked)

      // Parse existing device types into an array
      const currentDeviceTypes = advancedSearchData[type]?.split(",").filter(Boolean) || [];

      const updatedDeviceType = checked
        ? [...new Set([...currentDeviceTypes, value])] // Add value when checked
        : currentDeviceTypes.filter((device) => device.trim() !== value.trim()); // Remove value when unchecked

      // Update the state with the new comma-separated string
      setAdvancedSearchData({
        ...advancedSearchData,
        [type]: updatedDeviceType.join(","), // Convert back to a string
      });
    } else {
      setAdvancedSearchData({
        ...advancedSearchData,
        [type]: getParamValueByType(type, value, checked),
      });
    }
  };

  const getParamValueByType = (type, value, checked) => {
    if (type === "GeoLookUpType") {
      if (checked === false || value === "all") {
        return "";
      } else {
        return getLocatedCodeByName(value);
      }
    } else if (type === "ReasonForFailure") {
      if (checked === false || value === "all") {
        return "";
      } else {
        const reason = reasonForFailureFlags.find((item) => item.key === value);
        return reason.id;
      }
    } else {
      return value;
    }
  };

  const updateLocatedByState = (value, checked) => {
    const updatedState = allFlag.map((item) => {
      if (value === "all") {
        return { ...item, selected: checked };
      } else {
        if (item.key === value) {
          return { ...item, selected: checked };
        } else {
          return item;
        }
      }
    });
    setAllFlag(updatedState);
  };

  const updatedDeviceTypes = (value, checked) => {
    const updateState = devicetypes.map((item) => {
      if (item.key === value) {
        return { ...item, selected: checked };
      } else {
        return item;
      }
    });
    setDeviceType(updateState)
  }

  const clearLocatedBy = () => {
    const updatedState = allFlag.map((item) => {
      return { ...item, selected: false };
    });
    setAllFlag(updatedState);
  };

  const clearDeviceType = () => {
    const updatedState = devicetypes.map((item) => {
      return { ...item, selected: true };
    });
    setDeviceType(updatedState);
  };

  const updateReasonForFailState = (value, checked) => {
    const updatedState = reasonForFailureFlags.map((item) => {
      if (value === "all") {
        return { ...item, selected: checked };
      } else {
        if (item.key === value) {
          return { ...item, selected: checked };
        } else {
          return item;
        }
      }
    });
    setreasonForFailureFlags(updatedState);
  };

  const clearReasonsForFail = () => {
    const updatedState = reasonForFailureFlags.map((item) => {
      return { ...item, selected: false };
    });
    setreasonForFailureFlags(updatedState);
  };

  const saveSearch = (searchTitle) => {
    fetchAdvancedSearchDataSavedReport(searchTitle);
  };

  const fetchAdvancedSearchDataSavedReport = (searchTitle) => {
    const report = getUserReportByAPI(REPORT_S2T);

    let payload = { ...advancedSearchData };
    payload.appId = appContext.selectedUserApp.AppId;
    payload.JWTid = appContext.userDetails.JWTid;
    payload.reportId = report ? report.ReportId : SEARCH_S2T;

    var selectedRFFIds = -2;
    const allRFFSelected = reasonForFailureFlags?.find(
      (item) => item.id === -2 && item.selected === true
    );
    if (!allRFFSelected) {
      selectedRFFIds = reasonForFailureFlags
        ?.filter((item) => item.selected) // Filter by selected = true
        .map((item) => item.id) // Extract the IDs
        .join(","); // Join them into a comma-separated string
    }

    var selectedLocatedByIds = "";
    const allLBSelected = allFlag?.find(
      (item) => item.id === -1 && item.selected === true
    );
    if (!allLBSelected) {
      selectedLocatedByIds = allFlag
        ?.filter((item) => item.selected) // Filter by selected = true
        .map((item) => item.id) // Extract the IDs
        .join(","); // Join them into a comma-separated string
    }

    let updatedSavedReportPayload = {
      ...payload,
      ReasonForFailure: selectedRFFIds,
      GeoLookUpType: selectedLocatedByIds,
    };

    const savedSearchCriteriaList = localStorage.getItem(SEARCH_CRITERIA_LIST);

    if (savedSearchCriteriaList) {
      const json = JSON.parse(savedSearchCriteriaList);

      const saveData = [
        ...json,
        {
          key: generateRandomFiveNumber(),
          title: searchTitle,
          search: updatedSavedReportPayload,
        },
      ];
      localStorage.setItem(SEARCH_CRITERIA_LIST, JSON.stringify(saveData));
    } else {
      const saveData = [
        {
          key: generateRandomFiveNumber(),
          title: searchTitle,
          search: updatedSavedReportPayload,
        },
      ];
      localStorage.setItem(SEARCH_CRITERIA_LIST, JSON.stringify(saveData));
    }

    appContext.setRefreshSavedSearchSideMenu(Math.random());
  };

  const searchData = () => {
    fetchAdvancedSearchData();
  };

  const createRequestPayload = () => { };

  const fetchAdvancedSearchData = () => {
    const report = getUserReportByAPI(REPORT_S2T);

    let payload = { ...advancedSearchData };
    payload.appId = appContext.selectedUserApp.AppId;
    payload.JWTid = appContext.userDetails.JWTid;
    payload.reportId = report ? report.ReportId : SEARCH_S2T;

    var selectedRFFIds = -2;
    const allRFFSelected = reasonForFailureFlags.find(
      (item) => item.id === -2 && item.selected === true
    );
    if (!allRFFSelected) {
      selectedRFFIds = reasonForFailureFlags
        .filter((item) => item.selected) // Filter by selected = true
        .map((item) => item.id) // Extract the IDs
        .join(","); // Join them into a comma-separated string
    }

    var selectedLocatedByIds = "";
    const allLBSelected = allFlag.find(
      (item) => item.id === -1 && item.selected === true
    );
    if (!allLBSelected) {
      selectedLocatedByIds = allFlag
        .filter((item) => item.selected) // Filter by selected = true
        .map((item) => item.id) // Extract the IDs
        .join(","); // Join them into a comma-separated string
    }

    let updatedPayload = {
      ...payload,
      ReasonForFailure: selectedRFFIds,
      GeoLookUpType: selectedLocatedByIds,
    };

    setAdvancedSearchData(updatedPayload);
    if (closeModal) {
      closeModal();
    }
    setOpen(false);
    navigate("/search", {
      state: { searchParam: updatedPayload, advanceSearch: true },
    });
  };

  const clearAll = () => {
    setAdvancedSearchData(advancedSearchParam);
    clearLocatedBy();
    clearReasonsForFail();
    setclearInputstate(true);
    setIsShowDatePickers(false);
    setIsTransDateSelected(false);
    clearDeviceType();
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        slotProps={{
          backdrop: {
            onClick: (event) => event.stopPropagation(), // Prevent closing on backdrop click
          },
        }}
      >
        <Box
          sx={{
            ...modalStyle,
            outline: 0,
            backgroundColor:
              theme.palette.mode === "dark" ? "#1c1c1c" : "#d8d8d8",
          }}
        >
          <AdvancedSearch
            onDateSelect={onDateSelect}
            isShowDatePickers={isShowDatePickers}
            saveSearch={saveSearch}
            clearAll={clearAll}
            searchData={searchData}
            toggleAdvanced={toggleAdvanced}
            transactionPeriodStartDate={transactionPeriodStartDate}
            advancedSearchFormValues={advancedSearchFormValues}
            advancedSearchData={advancedSearchData}
            allFlag={allFlag}
            deviceTypes={devicetypes}
            devicePlaceholder={devicePlaceholder}
            transPeriod={transactionPeriod}
            transPlaceholder={transactionPlaceholder}
            UDIDPlaceholder={UDIDPlaceholder}
            clearInputstate={clearInputstate}
            reasonForFailureFlags={reasonForFailureFlags}
            isTransDateSelected={isTransDateSelected}
          />
        </Box>
      </Modal>
    </div>
  );
}

export const LOOKUP_MENUS = [
  {
    title: "All Checks",
    code: -2,
    items: [],
    selected: false
  },
  {
    title: "Approved Checks",
    code: 0,
    items: [],
    selected: true
  },
  {
    title: "Failed Checks",
    code: -1,
    selected: false,
    items: [
      {
        title: "Select All Failed Checks",
        code: -1,
        items: [],
        selected: false,
        colorCode: '#CB9871'
      },
      {
        title: "Outside Location",
        code: 3,
        items: [],
        selected: true,
        colorCode: '#CB0101'
      },
      {
        title: "Spoofing Technology",
        code: 101,
        selected: false,
        items: [
          {
            title: "Mock Location Detected",
            code: 7,
            selected: false,
            colorCode: '#E57373'
          },
          {
            title: "Developer Mode",
            code: 9,
            selected: false,
            colorCode: '#F06292'
          },
        ],
      },
      {
        title: "Emulator Detected",
        code: 15,
        selected: false,
        items: [],
        colorCode: '#F06292'
      },
      {
        title: "Modified Device",
        code: 103,
        selected: false,
        items: [
          {
            title: "Location Service Disabled",
            code: 5,
            selected: false,
            colorCode: '#D32F2F'
          },
          {
            title: "Modified Device",
            code: 6,
            selected: false,
            colorCode: '#880E4F'
          },
        ],
      },
      {
        title: "VPN Detected",
        code: 14,
        items: [],
        selected: false,
        colorCode: '#C51162'
      },
      {
        title: "Proxy Detected",
        code: 12,
        items: [],
        selected: false,
        colorCode: '#C51162'
      },
      {
        title: "Block Status",
        code: 104,
        selected: false,
        items: [
          {
            title: "Device Blocked",
            code: 10,
            selected: false,
            colorCode: '#D80073'
          },
          {
            title: "Account Blocked",
            code: 11,
            selected: false,
            colorCode: '#FF4700'
          },
          {
            title: "Device Blocked Globally",
            code: 13,
            selected: false,
            colorCode: '#FF0000'
          },
        ],
      },
      {
        title: "Others",
        code: 105,
        selected: false,
        items: [
          {
            code: 16,
            title: "Invalid Ip",
            selected: false,
            colorCode: '#D84315'
          },
          {
            title: "Unknown Location",
            code: 1,
            selected: false,
            colorCode: '#D84315'
          },
          {
            title: "Device Not Supported",
            code: 2,
            selected: false,
            colorCode: '#E91E63'
          },
          {
            title: "Undetermined Location",
            code: 8,
            selected: false,
            colorCode: '#F2453D'
          },
          {
            title: "Other",
            code: 4,
            selected: false,
            colorCode: '#FECDD2'
          },
        ],
      },
    ],
  },
];

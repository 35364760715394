import { useState, Fragment } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CommonListView from "../commonListView/CommonListView";
import { formatDateBasedOnLocale } from "../../utils/DateUtils";
import { Link } from "react-router-dom";
import { getColorCodeByReasonCode, getReasonsNameByCode } from "../../utils/CommonUtils";

export default function ACTTransactionsMultipleDialog(props) {
  const { moreItems, failedReasons } = props;
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dialog_columns = [
    {
      field: "Timestamp",
      headerName: "Date & Time",
      width: 180,
      display: "flex",
      valueFormatter: (params) => {
        return formatDateBasedOnLocale(params);
      },
    },
    {
      field: "DeviceType",
      headerName: "Device Type",
      width: 120,
      display: "flex",
    },
    {
      field: "DeviceId",
      headerName: "Device ID",
      width: 260,
      display: "flex",
      renderCell: (cellValues) => {
        return (
          <Link
            to={"/summary/device/" + cellValues.row.DeviceId}
            style={{
              color: theme.palette.text.primary,
              textDecoration: "underline",
            }}
            state={{ type: "device", id: cellValues.row.DeviceId }}
          >
            {cellValues.row.DeviceId}
          </Link>
        );
      },
    },
    {
      field: "AccountId",
      headerName: "Account ID",
      width: 220,
      display: "flex",
      renderCell: (cellValues) => {
        return (
          <Link
            to={"/summary/account/" + cellValues.row.AccountId}
            style={{
              color: theme.palette.text.primary,
              textDecoration: "underline",
            }}
            state={{ type: "account", id: cellValues.row.AccountId }}
          >
            {cellValues.row.AccountId}
          </Link>
        );
      },
    },
    {
      field: "ReasonForFailure",
      headerName: "Status",
      width: 200,
      display: "flex",
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "7px",
            }}
          >
            <div
              style={{
                minWidth: "10px",
                minHeight: "10px",
                backgroundColor: getColorCodeByReasonCode(
                  params.row.ReasonForFailure[0], failedReasons
                ),
                borderRadius: "6px",
              }}
            ></div>
            {getReasonsNameByCode(params.row.ReasonForFailure[0], failedReasons)}
          </div>
        );
      },
      valueGetter: (params) => {
        return getReasonsNameByCode(params, failedReasons);
      },
    },
  ];

  return (
    <Fragment>
      <button
        style={{
          fontSize: "0.8rem",
          color: "#1e86f0",
          padding: "2rem 1rem 2rem",
          fontWeight: "400",
        }}
        onClick={handleClickOpen}
      >
        {!open ? "view all checks" : "hide checks"}
      </button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: isMobile ? "95vw" : "1085px",
            maxWidth: "100%",
          },
        }}
      >
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <CloseIcon
              onClick={handleClose}
              sx={{
                fontSize: "1.5rem",
                margin: "0 0 1rem 0",
                cursor: "pointer",
                opacity: "0.7",
                "&:hover": {
                  opacity: "1",
                },
              }}
            />
          </Box>
          <Box>
            <CommonListView
              rows={moreItems}
              columns={dialog_columns}
              pageSize={10}
              getRowId={(row) => row.GeoReferenceId}
              height={"100%"}
              maxHeight={"770px"}
              toolbar={null}
              paginationMode={null}
              moreItems={moreItems}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}

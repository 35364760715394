import React, { useState, useEffect, createContext, useContext, useRef } from 'react';
import { useNavigate } from "react-router";
import { AppContext } from "../context/AppContext";
import { removeAuthInfo } from "../../src/utils/AuthUtils";
import Popup from '../popup/Popup';

// Create the context
const InactivityContext = createContext();

// Custom Hook to use Inactivity Context
export const useInactivity = () => {
  return useContext(InactivityContext);
};

//600000 = 10 minutes
// 60000 = 1 minutes
// Provider component
export const InactivityProvider = ({ children, timeoutDuration = 600000, warningDuration = 60000 }) => {
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  // let inactivityTimer;
  // let warningTimer;
  let isLoggedIn = !!appContext.userDetails;

  const inactivityTimer = useRef(null);
  const warningTimer = useRef(null);

  const resetTimers = () => {
    if (!isLoggedIn) {
      return
    }; // Avoid running timers if user is not logged in

    // Clear existing timers
    clearTimeout(inactivityTimer.current);
    clearTimeout(warningTimer.current);

    // Show warning before session timeout
    warningTimer.current = setTimeout(() => {
      setShowWarningPopup(true);
    }, timeoutDuration - warningDuration);

    // Log out user after timeout
    inactivityTimer.current = setTimeout(() => {
      setIsSessionExpired(true);
      appContext.clearAllStates();
      removeAuthInfo();
      navigate('/login');
      setShowWarningPopup(false);
    }, timeoutDuration);
  };

  useEffect(() => {
    if (!isLoggedIn) return; // Do not track inactivity if not logged in

    const handleUserActivity = () => {
      resetTimers();
      localStorage.setItem('userActivity', Date.now()); // Update activity timestamp
    };

    const events = ['mousemove', 'keydown', 'click', 'scroll'];

    events.forEach((event) => window.addEventListener(event, handleUserActivity));
    resetTimers(); // Start the timers initially

    return () => {
      events.forEach((event) => window.removeEventListener(event, handleUserActivity));
      clearTimeout(inactivityTimer);
      clearTimeout(warningTimer);
    };
  }, [isLoggedIn]); // Runs only when `isLoggedIn` changes

  useEffect(() => {
    if (!appContext.userDetails) {
      clearTimeout(inactivityTimer.current);
      clearTimeout(warningTimer.current);
      return; // Stop tracking if the user is not logged in
    }
    resetTimers();
  }, [isLoggedIn, appContext.userDetails]);

  const handleExtendSession = () => {
    setShowWarningPopup(false);
    resetTimers();
  };

  const handleLogout = () => {
    setIsSessionExpired(true);
    appContext.clearAllStates();
    removeAuthInfo();
    navigate('/login');
    setShowWarningPopup(false);
  };

  return (
    <InactivityContext.Provider value={{ isSessionExpired, resetTimers }}>
      {children}
      <Popup
        isVisible={showWarningPopup}
        title="Session Expiration Warning"
        message="Your session will expire soon. Do you want to extend it?"
        onConfirm={handleExtendSession}
        onCancel={handleLogout}
      />
    </InactivityContext.Provider>
  );
};

import React, { useContext, useState, useEffect } from "react";
import { Box } from "@mui/material";
import ACTDropdownWithCollapseMenuItemList from "./ACTDropdownWithCollapseMenuItemList";
import DashboardTabHeader from "../home/DashboardTabHeader";
import { AppContext } from "../../context/AppContext";
import "./ACTDashboardToolbarStyles.scss";
import FailedChecksChipList from "./ACTDropdownSelectedChipsList";

import { LOOKUP_MENUS } from "../../mock/menumock";

const ACTDashboardToolbar = (props) => {
  const { dashboardStates, setDashboardData } = useContext(AppContext);
  const appContext = useContext(AppContext);
  const { reasonsForFail, onRefreshData, totalRecords } = props;
  const selectedItem = dashboardStates["failed_reasons"] || null;
  const type = 5;
  const name = "failed_reasons";
  const options = reasonsForFail;

  const [menuItems, setMenuItems] = useState(LOOKUP_MENUS);
  const [failedChecksTitles, setFailedChecksTitles] = useState(["Outside Location"]);
  const [subMenuSelectedItems, setSubMenuSelectedItems] = useState(null);
  const [selectedInnerMenu, setSelectedInnerMenu] = useState(null);

  let found;

  // Find the selected item in LOOKUP_MENUS
  const findSelectedItem = (selectedItem) => {
    if (!selectedItem) return null;

    found = LOOKUP_MENUS.find((item) => item.code === selectedItem?.Code);
    return (
      found || {
        title: selectedItem.FriendlyName,
        code: selectedItem.Code,
        items: [],
        selected: true,
      }
    );
  };

  const [selectedItems, setSelectedItems] = useState(
    findSelectedItem(selectedItem) || null
  );

  // Sync `selectedItems` when `selectedItem` changes
  useEffect(() => {
    if (!selectedItem) {
      // Clear the state if no selectedItem
      setSelectedItems(null);
      resetMenuItems(); // Explicitly reset menuItems when selectedItem is null
      setSubMenuSelectedItems(null);
      // setShowFullList(false);
    } else {
      // Update the state based on session or fallback logic
      setSelectedItems(findSelectedItem(selectedItem));
    }
  }, [selectedItem]);

  // Reset Function
  const resetMenuItems = () => {
    setMenuItems([...LOOKUP_MENUS]); // Ensures a fresh array reference
  };

  // Handle parent or child selection
  const handleSelectionChange = (code, checked) => {
    // in inital load if default values getting unchecked, nothing selected in failed
    const SELECT_ALL_FAILED_CHECKS = '-1';
    const SELECT_ALL_CHECKS = '-2';
    const APPROVED_CHECKS = '0';
    const DEFAULT_IDS = '0, 3'
    let selectedItem;
    let selectedIdsString
    let codeStr = code.toString();

    const updatedItems = updateSelectionState(menuItems, code, checked);
    setMenuItems(updatedItems);

    // Update the failedChecksTitles state
    const updatedFailedChecksTitles = collectFailedChecksTitles(updatedItems);
    setFailedChecksTitles(updatedFailedChecksTitles);
    // selectedFailedChips(updatedFailedChecksTitles)

    if (onRefreshData) {
      // Approved checks must be the default one if none of the items selected
      let dynamicCode = (codeStr && !checked) ? DEFAULT_IDS : codeStr;
      selectedItem = { id: dynamicCode }
      setSelectedItems(selectedItem);
      // Collect all selected item IDs
      if (codeStr !== SELECT_ALL_FAILED_CHECKS) {
        let selectedIds = getSelectedItemIds(updatedItems);
        // Remove -1 (Select All Failed Checks) and -2 (Select All) from the list of selected IDs
        selectedIds = selectedIds.filter(
          (id) =>
            id.toString() !== SELECT_ALL_FAILED_CHECKS &&
            id.toString() !== SELECT_ALL_CHECKS &&
            id.toString() !== APPROVED_CHECKS &&
            id.toString() !== '101' &&
            id.toString() !== '103' &&
            id.toString() !== '104' &&
            id.toString() !== '105'
        );

        // If unchecked, remove the corresponding `code`
        if (!checked) {
          selectedIds = selectedIds.filter((id) => id.toString() !== codeStr);
        }
        // Convert selected IDs to a comma-separated string
        selectedIdsString = selectedIds.join(',');

        // Pass the updated ID string directly to `onRefreshData` without modifying `options`
        selectedItem = { id: selectedIdsString }
      }
      onRefreshData(selectedItem, type, name);
    }
  };

  // Helper function to recursively update the selection state
  const updateSelectionState = (items, targetCode, selected) => {
    let targetCodeToStr = targetCode?.toString();
    // if target code -1 and selected true make all checkboxes checked

    return items.map((item) => {
      if (targetCodeToStr === '-1') {
        // Mark all items and their children as selected
        return {
          ...item,
          selected: selected,
          items: item.items ? updateSelectionState(item.items, targetCode, selected) : [],
        }
      }
      else if (item.code === targetCode) {
        // Update current item's selected state and all children
        return {
          ...item,
          selected: selected,
          items: item.items ? updateSelectionState(item.items, null, selected) : [],
        };
      } else if (item.items) {
        // Update children recursively
        return {
          ...item,
          items: updateSelectionState(item.items, targetCode, selected),
        };
      }
      return item;
    });
  };

  const collectFailedChecksTitles = (items) => {
    let titles = [];
    items.forEach((item) => {
      // Exclude specific titles: "All Checks", "Approved Checks", and "Failed Checks"
      if (item.selected && !["All Checks", "Approved Checks", "Failed Checks", "Spoofing Technology", "Modified Device", "Block Status", "Others"].includes(item.title)) {
        titles.push({ title: item.title, code: item.code, selected: item.selected, colorCode: item.colorCode }); // Add the title of the current item
      }

      // Recursively collect titles from nested items
      if (item.items && item.items.length > 0) {
        titles = titles.concat(collectFailedChecksTitles(item.items));
      }
    });
    return titles;
  };

  // Helper function to collect selected item IDs
  const getSelectedItemIds = (items) => {
    let selectedIds = [];
    items.forEach((item) => {
      if (item.selected) {
        selectedIds.push(item.code); // Collect the code of the selected item
      }
      if (item.items && item.items.length > 0) {
        selectedIds = selectedIds.concat(getSelectedItemIds(item.items)); // Recursively collect from child items
      }
    });
    return selectedIds;
  };

  const refreshData = (newValue, type, key) => {
    if (onRefreshData) {
      onRefreshData(newValue, type);
      if (type === 3) {
        setDashboardData("date", {
          startDate: newValue,
          endDate: newValue,
        });
      } else {
        setDashboardData(key, newValue);
      }
    }
  };

  const dashboardHeaderButtonSelect = (value) => {
    if (value === 0) {
      appContext.setSelectedTab(0);
    } else {
      appContext.setSelectedTab(1);
    }
  };

  const handleSubMenuClick = (item) => {
    if (item.title === subMenuSelectedItems?.title) {
      const submenu = { ...item, selected: !subMenuSelectedItems?.selected };
      setSubMenuSelectedItems(submenu);
    } else {
      const submenu = { ...item, selected: true };
      setSubMenuSelectedItems(submenu);
    }

    if (
      item.code === 101 ||
      item.code === 102 ||
      item.code === 103 ||
      item.code === 104 ||
      item.code === 105 ||
      item.code === -1
    ) {
      return;
    }

    if (onRefreshData) {
      const selectedItem = options.find(
        (menuitem) => menuitem.id === item.code
      );
      onRefreshData(selectedItem, type, name);
    }
    setSelectedItems(item);
  };

  const handleMenuClick = (item) => {
    const isAllChecksOrApproved = item.code === -2 || item.code === 0;
    const resetChildrenSelection = (children) => {
      return children.map((child) => ({
        ...child,
        selected: false,
        items: child.items ? resetChildrenSelection(child.items) : [], // Recursively reset child items
      }));
    };
    const menulist = menuItems.map((menu) => {
      if (menu.code === item.code) {
        return { ...menu, selected: !item?.selected };
      } else if (isAllChecksOrApproved) {
        // Reset "Failed Checks" and their children
        return {
          ...menu,
          selected: false,
          items: menu.items ? resetChildrenSelection(menu.items) : [], // Reset children selection
        };
      } else {
        return { ...menu, selected: false };
      }
    });
    setMenuItems(menulist);
    if (
      item.code === 101 ||
      item.code === 102 ||
      item.code === 103 ||
      item.code === 104 ||
      item.code === 105 ||
      item.code === -1
    ) {
    } else {
      if (onRefreshData) {
        const selectedItem = options.find((menuitem) => {
          if (menuitem.id && item.code) {
            return menuitem.id === item.code;  // Match using id and code
          }
          menuitem.id = item.code;
          return menuitem.Code === item.code;  // Fallback to matching Code if id is not present
        });
        onRefreshData(selectedItem, type, name);
      }
      setSelectedItems(item);
    }
    updateSelectionState(menulist, '', '');
  };

  // Use Effect to update titles dynamically when `items` change
  useEffect(() => {
    const selectedTitles = collectFailedChecksTitles(menuItems);

    // If there are no selected menuItems, retain the default "Outside Location"
    if (selectedTitles.length === 0) {
      setFailedChecksTitles([]);
    } else {
      setFailedChecksTitles(selectedTitles);
    }
  }, [menuItems]);

  useEffect(() => {
    const storedIds = dashboardStates["failed_reasons"];
    if (!selectedItem) return;
    if (storedIds) {
      let selectedIds = "";
      if (typeof storedIds === "string") {
        selectedIds = storedIds; // Already in the required format
      } else if (typeof storedIds === "object" && storedIds.id) {
        selectedIds = storedIds.id; // Extract the value directly
      }

      // Ensure selectedIds is a string before using `.includes`
      if (typeof selectedIds === "string" && selectedIds.includes("-1")) {
        // If `-1` is present, select all failed checks
        const updatedMenuItems = selectAllFailedChecks(menuItems);
        setMenuItems(updatedMenuItems);

        // Persist the updated menu items to localStorage
        localStorage.setItem("menuItemsState", JSON.stringify(updatedMenuItems));
      }
      else {
        // Otherwise, update based on the selected IDs
        const updatedMenuItems = updateMenuItemsWithLocalStorage(menuItems, selectedIds);
        setMenuItems(updatedMenuItems);
      }
    }
  }, []);


  const selectAllFailedChecks = (menuItems) => {
    return menuItems.map((item) => {
      // Recursively set `selected: true` for all "failed checks"
      const updatedItems = item.items?.length ? selectAllFailedChecks(item.items) : [];

      return {
        ...item,
        selected: true, // Mark the item as selected
        items: updatedItems,
      };
    });
  };

  const updateMenuItemsWithLocalStorage = (menuItems, selectedIds) => {
    const selectedIdArray = selectedIds.toString().split(",").map(Number); // Convert string to an array of numbers
    return menuItems.map((item) => {
      const isSelected = selectedIdArray.includes(item.code);

      // If the item has nested items, recursively update them
      const updatedItems = item.items?.length
        ? updateMenuItemsWithLocalStorage(item.items, selectedIds)
        : [];

      return {
        ...item,
        selected: isSelected,
        items: updatedItems,
      };
    });
  };

  const handleInnerMenuClick = (menuItem) => {
    if (onRefreshData) {
      const selectedItem = options.find((item) => item.id === menuItem.code);
      onRefreshData(selectedItem, type, name);
    }
    setSelectedItems(menuItem);
    // setAnchorEl(null);
    setSelectedInnerMenu(menuItem);
  };


  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      {/* Left and Middle combined */}
      <Box
        sx={{
          flexShrink: 0, // Prevent shrinking
          padding: "8px",
        }}
      >
        {reasonsForFail && (
          <ACTDropdownWithCollapseMenuItemList
            title={"Select Reason for Fail"}
            onRefreshData={refreshData}
            totalRecords={totalRecords}
            handleSelectionChange={handleSelectionChange}
            handleMenuClick={handleMenuClick}
            handleSubMenuClick={handleSubMenuClick}
            menuItems={menuItems}
            selectedItems={selectedItems}
            subMenuSelectedItems={subMenuSelectedItems}
            selectedInnerMenu={selectedInnerMenu}
            failedChecksTitles={failedChecksTitles}
          />
        )}
      </Box>
      <Box
        sx={{
          flex: 1, // Take up remaining space
          padding: "8px",
          overflow: 'hidden',
        }}
      >
        <FailedChecksChipList
          failedChecksTitles={failedChecksTitles}
          handleSelectionChange={handleSelectionChange}
        />
      </Box>
      {/* Right Child */}
      <Box
        sx={{
          flexShrink: 0, // Prevent shrinking
          padding: "8px",
        }}
      >
        <DashboardTabHeader
          onTabSelect={dashboardHeaderButtonSelect}
          selectedTabItem={appContext.selectedTab}
        />
      </Box>
    </Box>
  )
};

export default ACTDashboardToolbar;

import React, { useState, useRef, useEffect } from 'react';

import Chip from '@mui/material/Chip';

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { IconButton } from "@mui/material";

import { Box } from "@mui/system";
import { useTheme } from "@mui/material/styles";

const FailedChecksChipList = (props) => {
    const { failedChecksTitles, handleSelectionChange } = props
    const theme = useTheme();
    const containerRef = useRef(null);
    const chipRefs = useRef([]);

    const [isOverflowing, setIsOverflowing] = useState(false);

    const handleDelete = (code, title, checked) => {
        let status = checked ? false : true;
        handleSelectionChange(code, status)
    };

    const scrollLeft = () => {
        if (containerRef.current) {
            containerRef.current.scrollLeft -= 600; // Adjust scroll amount as needed
        }
    };

    const scrollRight = () => {
        if (containerRef.current) {
            containerRef.current.scrollLeft += 600; // Adjust scroll amount as needed
        }
    };

    useEffect(() => {
        const checkOverflow = () => {
            if (containerRef.current && chipRefs.current.length > 0) {
                const containerWidth = containerRef.current.offsetWidth;
                const totalItemsWidth = chipRefs.current.reduce((acc, chipRef) => acc + (chipRef ? chipRef.offsetWidth : 0), 0);
                setIsOverflowing(totalItemsWidth > containerWidth);
            }
        };

        // Run the check on initial render and whenever the failedChecksTitles change
        checkOverflow();
        window.addEventListener('resize', checkOverflow);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', checkOverflow);
        };
    }, [failedChecksTitles]);

    return (
        <Box
            // ref={containerRef}
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                overflow: 'hidden',
                borderRadius: '5px',
                marginTop: '12px',
            }}
        >
            {isOverflowing &&
                <IconButton
                    onClick={() => scrollLeft()}
                    disabled={!containerRef.current || containerRef.current.scrollLeft === 0}
                    sx={{
                        color: theme.palette.text.primary,
                        marginRight: '8px',
                    }}
                >
                    <ArrowBackIosNewIcon fontSize="small" />
                </IconButton>}
            <Box
                ref={containerRef}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    width: '100%',
                    overflowX: 'auto',
                    overflowY: 'hidden',
                    padding: '8px',
                    gap: '8px',
                    scrollBehavior: 'smooth',
                    '&::-webkit-scrollbar': { display: 'none' },
                    msOverflowStyle: 'none',
                    scrollbarWidth: 'none',
                    paddingRight: '16px', // Prevent chips from getting cut off
                }}
            >
                {failedChecksTitles && failedChecksTitles.length > 0 && (
                    failedChecksTitles.map((itemName, index) => (
                        <Chip
                            key={index}
                            label={itemName.title}
                            variant="outlined"
                            onDelete={() => handleDelete(itemName.code, itemName.title, itemName.selected)}
                            sx={{
                                borderColor: theme.palette.mode === "dark" ? "#555" : "#ccc",
                                color: '#fff',
                                border: 'none',
                                backgroundColor: itemName.colorCode,
                                '& .MuiChip-deleteIcon': {
                                    // color: theme.palette.text.secondary,
                                    color: '#fff'
                                },
                            }}
                            color="primary"
                            ref={el => chipRefs.current[index] = el}
                        />
                    ))
                )}
            </Box>
            {
                isOverflowing &&
                <IconButton
                    onClick={() => scrollRight()}
                    disabled={
                        !containerRef.current ||
                        containerRef.current.scrollLeft + containerRef.current.offsetWidth >= containerRef.current.scrollWidth
                    }
                    sx={{
                        color: theme.palette.text.primary,
                        marginLeft: '8px',
                    }}
                >
                    <ArrowForwardIosIcon fontSize="small" />
                </IconButton>
            }
        </Box >
    )
}

export default FailedChecksChipList;
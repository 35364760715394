import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import './Popup.css';

const Popup = ({ isVisible, title, message, onConfirm, onCancel }) => {
    const popupRef = useRef();

    const handleOverlayClick = (e) => {
        if (popupRef.current && !popupRef.current.contains(e.target)) {
            onConfirm(); // Trigger the cancel action if clicked outside
        }
    };

    if (!isVisible) return null;

    return (
        <div className="popup-overlay" onClick={handleOverlayClick}>
            <div className="popup-container" ref={popupRef}>
                <h2>{title}</h2>
                <p>{message}</p>
                <div className="popup-actions">
                    <button onClick={onConfirm} className="popup-btn popup-confirm">Extend Session</button>
                    <button onClick={onCancel} className="popup-btn popup-cancel">Logout</button>
                </div>
            </div>
        </div>
    );
};

Popup.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    title: PropTypes.string,
    message: PropTypes.string,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

Popup.defaultProps = {
    title: 'Warning',
    message: 'Your session is about to expire. Do you want to extend it?',
};

export default Popup;

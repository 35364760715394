import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { useContext } from "react";
import { AppContext } from "../context/AppContext";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../components/loader/Loader";
import { useTheme } from "@emotion/react";

export default function ForgotPasswordTokenUsed() {
  const appContext = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();

  const from = location.state?.from?.pathname || "/";
  const [showLoader, setShowLoader] = useState("");

  const theme = useTheme();

  const goToForgotPasswordEmail = () => {
    navigate('/forgotten-password');
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100vw",
        height: "100%",
        minHeight: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "25rem",
          maxWidth: "90vw",
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "2rem",
            fontWeight: "900",
            color: theme.palette.text.primary,
          }}
        >
          Token Already In Use
        </Typography>
        <Typography
          sx={{
            fontSize: "1rem",
            fontWeight: "400",
            margin: "0.25rem 0 0 0",
            width: "20rem",
            maxWidth: "90vw",
            lineHeight: "1.2",
            color: theme.palette.text.primary,
          }}
        >
          Please write something in here about what this means and what to do
        </Typography>
        <Button
            onClick={goToForgotPasswordEmail}
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 7,
              mb: 2,
              width: "12.5rem",
              height: "2.5rem",
              borderRadius: 10,
              boxShadow: 0,
              bgcolor: "#1e86f0",
              "&:hover": {
                boxShadow: 0,
                bgcolor: "#4da6ff",
              },
            }}
          >
            request new email
          </Button>
      </Box>
      {showLoader && <Loader />}
    </Box>
  );
}

import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "material-react-toastify";

import { Box, Button, Grid, IconButton } from "@mui/material";
import ACTPieCard from "../charts/ACTPieCard";
import ACTCardDataGridTable from "../shared/ACTCardDataGridTable";
import SummaryMostPopular from "./SummaryMostPopular";
import SummaryDeviceInfo from "./SummaryDeviceInfo";
import { AppContext } from "../../context/AppContext";
import { Link } from "react-router-dom";
import { formatDateBasedOnLocale } from "../../utils/DateUtils";
import {
  getLocatedNameByCode,
  getReasonForLookupNameByCode,
  getReasonForLookupColorByCode,
} from "../../utils/CommonUtils";
import { exportToCsv } from "../../utils/CommonUtils";
import { getCurrentDateYYYYMMDDWithTime } from "../../utils/DateUtils";
import SummaryToolbar from "./SummaryToolbar";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Loader from "../loader/Loader";
import {
  blockAccount,
  blockDevice,
  unblockAccount,
  unblockDevice,
} from "../../services/BlockedAccountAndDeviceService";
import {
  showCatchedExceptionOnToast,
  validateAnsShowServerException,
} from "../../utils/ExceptionUtils";
import ACTFormDialog from "../shared/ACTFormDialog";
import MapSummaryDialog from "../home/maps/dialogmap/MapSummaryDialog";

const SummaryPageContainer = (props) => {
  const {
    summary,
    lookupTabHandler,
    summaryType,
    summaryId,
    refreshData,
    theme,
  } = props;
  const appContext = useContext(AppContext);
  const navigate = useNavigate();

  const [showLoader, setShowLoader] = useState(false);
  const [showFormDialog, setShowFormDialog] = useState(false);
  const [isMultipleSelected, setIsMultipleSelected] = useState(false);
  const [selectedAccountsOrDevices, setSelectedAccountsOrDevices] =
    useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const onLatLngClick = (params) => {
    if (summaryType === "device") {
      const deviceInfo = summary.Data.Results.Dev1Info.OsVersion;
      const selectedItem = {
        ...params.row,
        summaryType: summaryType,
        summaryId: summaryId,
        deviceInfo: deviceInfo,
      };
      setSelectedLocation(selectedItem);
    } else {
      const selectedItem = {
        ...params.row,
        summaryType: summaryType,
        summaryId: summaryId,
      };
      setSelectedLocation(selectedItem);
    }
  };

  const handleCloseMap = () => {
    setSelectedLocation(null);
  };

  const device_columns = [
    {
      field: "AccountId",
      headerName: "Account ID",
      width: 220,
      display: "flex",
      renderCell: (cellValues) => {
        return (
          <Link
            to={"/summary/account/" + cellValues.row.AccountId}
            style={{
              color: theme.palette.text.primary,
              textDecoration: "underline",
            }}
            state={{ type: "account", id: cellValues.row.AccountId }}
          >
            {cellValues.row.AccountId}
          </Link>
        );
      },
    },
    {
      field: "LastSeen",
      headerName: "Last Seen",
      width: 180,
      display: "flex",
      valueFormatter: (params) => {
        return formatDateBasedOnLocale(params);
      },
    },
    {
      field: "FirstSeen",
      headerName: "First Seen",
      width: 200,
      display: "flex",
      valueFormatter: (params) => {
        return formatDateBasedOnLocale(params);
      },
    },
    {
      field: "location",
      headerName: "Last known location",
      width: 200,
      display: "flex",
      renderCell: (params) => {
        return (
          <>
            <button
              onClick={() => onLatLngClick(params)}
              style={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              {params.row.Latitude + ", " + params.row.Longitude}
            </button>
          </>
        );
      },
    },
    {
      field: "LocatedBy",
      headerName: "Located by",
      width: 150,
      display: "flex",
      valueFormatter: (params) => {
        return getLocatedNameByCode(params);
      },
    },
    {
      field: "TotalActivity",
      headerName: "Total Activity",
      width: 150,
      display: "flex",
    },
    {
      field: "Status",
      headerName: "Status",
      width: 200,
      display: "flex",
    },
  ];

  const account_columns = [
    {
      field: "DeviceId",
      headerName: "Device ID",
      width: 330,
      display: "flex",
      renderCell: (cellValues) => {
        return (
          <Link
            to={"/summary/device/" + cellValues.row.DeviceId}
            style={{
              color: theme.palette.text.primary,
              textDecoration: "underline",
            }}
            state={{ type: "device", id: cellValues.row.DeviceId }}
          >
            {cellValues.row.DeviceId}
          </Link>
        );
      },
    },
    {
      field: "LastSeen",
      headerName: "Last Seen",
      width: 200,
      display: "flex",
      valueFormatter: (params) => {
        return formatDateBasedOnLocale(params);
      },
    },
    {
      field: "FirstSeen",
      headerName: "First Seen",
      width: 200,
      display: "flex",
      valueFormatter: (params) => {
        return formatDateBasedOnLocale(params);
      },
    },
    {
      field: "location",
      headerName: "Last known location",
      width: 200,
      display: "flex",
      renderCell: (params) => {
        return (
          <>
            <button
              onClick={() => onLatLngClick(params)}
              style={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              {params.row.Latitude + ", " + params.row.Longitude}
            </button>
          </>
        );
      },
    },
    {
      field: "LocatedBy",
      headerName: "Located bys",
      width: 150,
      display: "flex",
      valueFormatter: (params) => {
        return getLocatedNameByCode(params);
      },
    },
    {
      field: "TotalActivity",
      headerName: "Total Activity",
      width: 150,
      display: "flex",
    },
    {
      field: "Status",
      headerName: "Status",
      width: 200,
      display: "flex",
    },
  ];

  const getFailedDevice = () => {
    const data =
      summaryType === "device"
        ? summary.Data.Results.Dev1Activity
        : summary.Data.Results.Acc1Activity;
    if (!data) return { results: [] };

    if (data.Failed === 0 && data.Passed === 0) {
      return { results: [] };
    } else {
      const total = data.Failed + data.Passed;
      const percentFailed = (data.Failed / total) * 100;
      const percentSuccess = (data.Passed / total) * 100;

      const results = [
        {
          value: data.Failed,
          title: "Failed " + percentFailed.toFixed(2) + "%",
          color: "#D32F2F",
        },
        {
          value: data.Passed,
          title: "Passed " + percentSuccess.toFixed(2) + "%",
          color: "#43A047",
        },
      ];
      return { results: results };
    }
  };

  const getReasonsForFail = () => {
    if (!appContext.failedReasons) return { results: [] };
    const data = summary.Data.Results.Dev1Fail;
    if (!data) return { results: [] };

    const values = data.map((item) => {
      const itemFound = appContext.failedReasons.find(
        (reasonItem) => reasonItem.Code === item.ReasonForFailure
      );
      if (itemFound) {
        return {
          id: item.ReasonForFailure,
          value: item.TotalNumber,
          color: itemFound.Color,
          title: itemFound.FriendlyName,
        };
      } else {
        return {
          id: null,
          value: null,
          color: null,
          title: null,
        };
      }
    });
    
    const sortedValues = values.sort((a, b) => b.value - a.value);
    return { results: sortedValues };
  };

  const getReasonsForLookup = () => {
    const data = summary.Data.Results.Acc1Lookups;
    if (!data) return { results: [] };

    const values = data.map((item) => {
      return {
        id: parseInt(item.ReasonForLookup),
        value: item.TotalNumber,
        color: getReasonForLookupColorByCode(item.ReasonForLookup),
        title: getReasonForLookupNameByCode(item.ReasonForLookup),
      };
    });
    const sortedValues = values.sort((a, b) => b.value - a.value);
    return { results: sortedValues };
  };

  const onActivityClick = () => {
    if (lookupTabHandler) {
      lookupTabHandler(1);
    }
  };

  const onReasonsForFailClick = () => {
    if (lookupTabHandler) {
      lookupTabHandler(1);
    }
  };

  // const onExportData = () => {
  //   const strDateTime = getCurrentDateYYYYMMDDWithTime();
  //   const filename = summaryId + "_" + summaryType + "_" + strDateTime + ".csv";
  //   if (summaryType === "device" && summary.Data.Results.Dev1Accounts) {
  //     const data = summary.Data.Results.Dev1Accounts.map((item) => {
  //       return {
  //         "Customer (AccountId)": item.AccountId,
  //         "Last Seen": formatDateBasedOnLocale(item.LastSeen),
  //         "First Seen": formatDateBasedOnLocale(item.FirstSeen),
  //         "Last known location": item.Latitude + ", " + item.Longitude,
  //         "Located By": getLocatedNameByCode(item.LocatedBy),
  //         "Total Activity": item.TotalActivity,
  //         Status: item.Status,
  //       };
  //     });
  //     exportToCsv(filename, data);
  //   } else if (summaryType === "account" && summary.Data.Results.Acc1Devices) {
  //     const data = summary.Data.Results.Acc1Devices.map((item) => {
  //       return {
  //         "Device ID": item.DeviceId,
  //         "Last Seen": formatDateBasedOnLocale(item.LastSeen),
  //         "First Seen": formatDateBasedOnLocale(item.FirstSeen),
  //         "Last known location": item.Latitude + ", " + item.Longitude,
  //         "Located By": getLocatedNameByCode(item.LocatedBy),
  //         "Total Activity": item.TotalActivity,
  //         Status: item.Status,
  //       };
  //     });
  //     exportToCsv(filename, data);
  //   }
  // };

  const onPrintData = () => {
    // window.print();
  };

  const getItemCount = () => {
    if (!summary) return 0;
    if (summaryType === "device") {
      return summary.Data.Results.Dev1Accounts
        ? summary.Data.Results.Dev1Accounts.length
        : 0;
    } else if (summaryType === "account") {
      return summary.Data.Results.Acc1Devices
        ? summary.Data.Results.Acc1Devices.length
        : 0;
    }
    return 0;
  };

  const onClickBack = () => {
    navigate(-1);
  };

  const getTableHeaderToolbar = () => {
    return (
      <SummaryToolbar
        summaryType={summaryType}
        count={getItemCount()}
        // exportHandler={onExportData}
        // printHandler={onPrintData}
        blockHandler={onBlockDevicesOrAccountsClick}
      />
    );
  };

  const blockUserAccount = (payload) => {
    try {
      setShowLoader(true);
      blockAccount(payload)
        .then((response) => {
          if (response.Status === 0) {
            if (refreshData) refreshData();
            setSelectedAccountsOrDevices(null);
          } else {
            validateAnsShowServerException(
              toast,
              2,
              response.Status,
              appContext
            );
          }
        })
        .catch((err) => {
          showCatchedExceptionOnToast(toast, 2, err);
        })
        .finally(() => {
          setShowLoader(false);
        });
    } catch (err) {
      showCatchedExceptionOnToast(toast, 2, err);
    }
  };

  const unBlockUserAccount = (payload) => {
    try {
      setShowLoader(true);
      unblockAccount(payload)
        .then((response) => {
          if (response.Status === 0) {
            if (refreshData) refreshData();
            setSelectedAccountsOrDevices(null);
          } else {
            validateAnsShowServerException(
              toast,
              2,
              response.Status,
              appContext
            );
          }
        })
        .catch((err) => {
          showCatchedExceptionOnToast(toast, 2, err);
        })
        .finally(() => {
          setShowLoader(false);
        });
    } catch (err) {
      showCatchedExceptionOnToast(toast, 2, err);
    }
  };

  const blockUserDevice = (payload) => {
    try {
      setShowLoader(true);
      blockDevice(payload)
        .then((response) => {
          if (response.Status === 0) {
            if (refreshData) refreshData();
            setSelectedAccountsOrDevices(null);
          } else {
            validateAnsShowServerException(
              toast,
              2,
              response.Status,
              appContext
            );
          }
        })
        .catch((err) => {
          showCatchedExceptionOnToast(toast, 2, err);
        })
        .finally(() => {
          setShowLoader(false);
        });
    } catch (err) {
      showCatchedExceptionOnToast(toast, 2, err);
    }
  };

  const unBlockUserDevice = (payload) => {
    try {
      setShowLoader(true);
      unblockDevice(payload)
        .then((response) => {
          if (response.Status === 0) {
            if (refreshData) refreshData();
            setSelectedAccountsOrDevices(null);
          } else {
            validateAnsShowServerException(
              toast,
              2,
              response.Status,
              appContext
            );
          }
        })
        .catch((err) => {
          showCatchedExceptionOnToast(toast, 2, err);
        })
        .finally(() => {
          setShowLoader(false);
        });
    } catch (err) {
      showCatchedExceptionOnToast(toast, 2, err);
    }
  };

  const onBlockAccountOrDeviceClick = () => {
    setShowFormDialog(true);
    setIsMultipleSelected(false);
  };

  const onBlockDevicesOrAccountsClick = () => {
    const type = summaryType === "account" ? "device(s)" : "account(s)";

    if (!selectedAccountsOrDevices)
      return toast.info("Select " + type + " to block");

    if (selectedAccountsOrDevices && selectedAccountsOrDevices.length === 0)
      return toast.info("Select " + type + " to block");

    if (summaryType === "account") {
      const eligibleToBlock = selectedAccountsOrDevices.filter((item) => {
        const fullItem = summary.Data.Results.Acc1Devices.find(
          (data) => data.DeviceId === item
        );
        return fullItem && fullItem.Status === "active";
      });
      if (eligibleToBlock.length != selectedAccountsOrDevices.length) {
        return toast.info(
          "Some of the selected device(s) has already been blocked. Only select active device to block."
        );
      }
    } else {
      const eligibleToBlock = selectedAccountsOrDevices.filter((item) => {
        const fullItem = summary.Data.Results.Dev1Accounts.find(
          (data) => data.AccountId === item
        );
        return fullItem && fullItem.Status === "active";
      });
      if (eligibleToBlock.length != selectedAccountsOrDevices.length) {
        return toast.info(
          "Some of the selected account(s) has already been blocked. Only select active account to block."
        );
      }
    }

    setShowFormDialog(true);
    setIsMultipleSelected(true);
  };

  const blockAccountOrDevice = (notes) => {
    if (summaryType === "device") {
      const payload = {
        JWTid: appContext.userDetails.JWTid,
        appId: appContext.selectedUserApp.AppId,
        DeviceId: summaryId,
        Note: notes,
      };
      if (summary.Data.Results.Dev1Info?.Status === "active") {
        blockUserDevice(payload);
      } else {
        unBlockUserDevice(payload);
      }
    } else {
      const payload = {
        JWTid: appContext.userDetails.JWTid,
        appId: appContext.selectedUserApp.AppId,
        AccountId: summaryId,
        Note: notes,
      };
      if (summary.Data.Results.Acc1Info?.Status === "active") {
        blockUserAccount(payload);
      } else {
        unBlockUserAccount(payload);
      }
    }
  };

  const blockAccountsOrDevices = (notes) => {
    if (!selectedAccountsOrDevices) return;

    if (selectedAccountsOrDevices && selectedAccountsOrDevices.length === 0)
      return;

    const ids = selectedAccountsOrDevices.map((item) => item).join(",");
    if (summaryType === "account") {
      const payload = {
        JWTid: appContext.userDetails.JWTid,
        appId: appContext.selectedUserApp.AppId,
        DeviceId: ids,
        Note: notes,
      };
      blockUserDevice(payload);
    } else {
      const payload = {
        JWTid: appContext.userDetails.JWTid,
        appId: appContext.selectedUserApp.AppId,
        AccountId: ids,
        Note: notes,
      };
      blockUserAccount(payload);
    }
  };

  const onSubmit = (notes) => {
    if (isMultipleSelected) {
      blockAccountsOrDevices(notes);
    } else {
      blockAccountOrDevice(notes);
    }
  };

  const getPopupTitle = () => {
    if (summaryType === "device") {
      if (summary.Data.Results.Dev1Info?.Status === "active") {
        if (isMultipleSelected) {
          return "Block Account";
        } else {
          return "Block Device";
        }
      } else {
        if (isMultipleSelected) {
          return "Block Account";
        } else {
          return "Unblock Device";
        }
      }
    } else if (summaryType === "account") {
      if (summary.Data.Results.Acc1Info?.Status === "active") {
        if (isMultipleSelected) {
          return "Block Device";
        } else {
          return "Block Account";
        }
      } else {
        if (isMultipleSelected) {
          return "Block Device";
        } else {
          return "Unblock Account";
        }
      }
    }
  };

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box sx={{ flexGrow: 1 }}>
          <IconButton onClick={onClickBack}>
            <ChevronLeftIcon style={{ color: theme.palette.text.primary }} />
          </IconButton>
        </Box>
        {summaryType === "account" && summary.Data.Results.Acc1Info?.Status && (
          <Button variant="outlined" onClick={onBlockAccountOrDeviceClick}>
            {summary.Data.Results.Acc1Info?.Status === "active"
              ? "Block Account"
              : "Unblock Account"}
          </Button>
        )}
        {summaryType === "device" && summary.Data.Results.Dev1Info?.Status && (
          <Button variant="outlined" onClick={onBlockAccountOrDeviceClick}>
            {summary.Data.Results.Dev1Info?.Status === "active"
              ? "Block Device"
              : "Unblock Device"}
          </Button>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginTop: "1rem",
          gap: "1rem",
        }}
      >
        <Grid
          sx={{ flexGrow: 1 }}
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 8, lg: 12 }}
        >
          <Grid item xs={6} sm={4} md={4}>
            <SummaryDeviceInfo
              title={summaryType === "device" ? "Device info" : "Account Info"}
              data={
                summaryType === "device"
                  ? summary.Data.Results.Dev1Info
                  : summary.Data.Results.Acc1Info
              }
              summaryType={summaryType}
              summaryId={summaryId}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={4} lg={4}>
            <ACTPieCard
              data={getFailedDevice()}
              title={"Activity"}
              link={true}
              activityHandler={onActivityClick}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={4} lg={4}>
            <ACTPieCard
              data={
                summaryType === "device"
                  ? getReasonsForFail()
                  : getReasonsForLookup()
              }
              title={
                summaryType === "device"
                  ? summary.Data?.Results?.Dev1Fail?.length >= 3
                    ? "Top 3 reasons for fail"
                    : summary.Data?.Results?.Dev1Fail?.length === 1
                    ? "Reason for fail"
                    : "Reasons for fail"
                  : "Total Check"
              }
              link={true}
              activityHandler={onReasonsForFailClick}
            />
          </Grid>
        </Grid>

        <ACTCardDataGridTable
          type={1}
          data={
            summaryType === "device"
              ? summary.Data.Results.Dev1Accounts
              : summary.Data.Results.Acc1Devices
          }
          summaryType={summaryType}
          summaryId={summaryId}
          columns={summaryType === "device" ? device_columns : account_columns}
          toolbar={getTableHeaderToolbar()}
          onRowSelection={(ids) => {
            setSelectedAccountsOrDevices(ids);
          }}
        />
        <SummaryMostPopular
          summaryType={summaryType}
          data={
            summaryType === "device"
              ? summary.Data.Results.Dev1Popular
              : summary.Data.Results.Acc1Popular
          }
        />
      </Box>
      {showLoader && <Loader />}
      <ACTFormDialog
        openDialog={showFormDialog}
        title={getPopupTitle()}
        closeHandler={setShowFormDialog}
        submitHanlder={onSubmit}
      />
      {selectedLocation && (
        <MapSummaryDialog
          params={selectedLocation}
          open={selectedLocation ? true : false}
          onClose={handleCloseMap}
        />
      )}
    </Box>
  );
};

export default SummaryPageContainer;

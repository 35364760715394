import {
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";

import Login from "../pages/Login";
import ForgottenPassword from "../pages/ForgottenPassword";
import ResetPassword from "../pages/ResetPassword";
import ForgotPasswordTokenInvalid from "../pages/ForgotPasswordTokenInvalid";
import ForgotPasswordTokenUsed from "../pages/ForgotPasswordTokenUsed";
import ForgotPasswordTokenError from "../pages/ForgotPasswordTokenError";
import ForgotPasswordUserNotFound from "../pages/ForgotPasswordUserNotFound";

const LoginRoutes = () => {
  const location = useLocation();
  return (
    <Routes>
      <Route
        path="*"
        element={<Navigate to="/login" state={{ from: location }} replace />}
      />
      <Route path="/login" element={<Login />} />
      <Route path="/forgotten-password" element={<ForgottenPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/token-invalid" element={<ForgotPasswordTokenInvalid />} />
      <Route path="/token-used" element={<ForgotPasswordTokenUsed />} />
      <Route path="/token-error" element={<ForgotPasswordTokenError />} />
      <Route path="/user-not-found" element={<ForgotPasswordUserNotFound />} />
    </Routes>
  );
};

export default LoginRoutes;

import * as React from "react";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(data, selectData, theme) {
  return {
    fontWeight:
      selectData.indexOf(data) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function SelectDropdownMenu(props) {
  const {
    options,
    placeHolderText,
    handleSelect,
    clearInputstate,
    selectedValue,
  } = props;
  const theme = useTheme();
  const [selectData, setData] = React.useState([]);
  React.useEffect(() => {
    setData([]);
  }, [clearInputstate]);

  React.useEffect(() => {
    if (selectedValue) {
      setData([selectedValue]);
    } else {
      setData([]);
    }
  }, [selectedValue]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    // Handle single-selection case
    const newValues = Array.isArray(value) ? value : [value];
    setData(newValues);
    handleSelect(newValues.join(",")); // Pass comma-separated values to parent
  };

  return (
    <div>
      <FormControl fullWidth sx={{ m: 1, minWidth: 242 }}>
        <Select
          sx={{
            paddingLeft: 1.5,
            height: 42,
            "& .MuiSelect-select": {
              color: theme.palette.text.primary + '!important',
            },
          }}
          defaultValue={""}
          displayEmpty
          value={selectData.length > 0 ? selectData : ""}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <p style={{ color: theme.palette.text.primary }}>{placeHolderText}</p>;
            }
            return selected.join(",");
          }}
          MenuProps={MenuProps}
          inputProps={{ "aria-label": "Without label" }}
        >
          {options.map((data) => (
            <MenuItem
              key={data}
              value={data}
              style={getStyles(data, selectData, theme)}
            >
              {data}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { useContext } from "react";
import { AppContext } from "../context/AppContext";
import { useLocation, useNavigate } from "react-router-dom";
import TextFieldOutlinedInput from "../components/shared/TextFieldOutlinedInput/TextFieldOutlinedInput";
import { saveUserInfo } from "../utils/AuthUtils";
import Loader from "../components/loader/Loader";
import { useTheme } from "@emotion/react";

export default function ResetPassword() {
  const appContext = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();

  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [isFilledNewPassword, setIsFilledNewPassword] = useState("");
  const [isFilledConfirmNewPassword, setIsFilledConfirmNewPassword] =
    useState("");
  const from = location.state?.from?.pathname || "/";
  const [showLoader, setShowLoader] = useState("");
  const [message, setMessage] = useState(null);

  // const handleSubmit = (event) => {
  //     event.preventDefault();
  //     try {
  //         const payload = {
  //             newpassword: newPassword,
  //         };

  //         setShowLoader(true);
  //         xxxxxx(payload)
  //         .then((response) => {
  //             if (response.Status === 0) {
  //                 appContext.setUserDetails(response);
  //                 appContext.xxxxxxxxx({

  //                 });
  //                 saveUserInfo(response.Data);
  //             }
  //         })
  //         .catch((err) => {
  //             console.log(err);
  //         })
  //         .finally(() => {
  //             setShowLoader(false);
  //         });
  //     } catch (err) {
  //         console.log(err);
  //       }
  // };

  const onEnterNewPassword = (value) => {
    if (value.length === 0) {
      setIsFilledNewPassword(false);
    } else {
      setIsFilledNewPassword(true);
    }
    setNewPassword(value);
  };

  const onEnterConfiremNewPassword = (value) => {
    if (newPassword === value) {
      setIsFilledConfirmNewPassword(true);
      setMessage(null);
    } else {
      setIsFilledConfirmNewPassword(false);
      setMessage("Passwords do not match");
    }
    setConfirmNewPassword(value);
  };

  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100vw",
        height: "100%",
        minHeight: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "25rem",
          maxWidth: "90vw",
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "2rem",
            fontWeight: "900",
            color: theme.palette.text.primary,
          }}
        >
          Reset Password
        </Typography>
        <Typography
          sx={{
            fontSize: "1rem",
            fontWeight: "400",
            margin: "0.25rem 0 1.5rem 0",
            width: "20rem",
            maxWidth: "90vw",
            lineHeight: "1.2",
            color: theme.palette.text.primary,
          }}
        >
          Enter and confirm your new password
        </Typography>
        <Box
          component="form"
          noValidate
          //   onSubmit={handleSubmit}
          sx={{
            mt: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <TextFieldOutlinedInput
            margin="normal"
            required
            fullWidth
            id="enterNewPassword"
            label="Enter new password"
            name="enterNewPassword"
            autoComplete="off"
            placeHolderText={"Enter new password"}
            onTextChange={onEnterNewPassword}
            sx={{
              mt: 7,
              mb: 2,
              width: "12.5rem",
              height: "2.5rem",
              borderRadius: 10,
              boxShadow: 0,
            }}
          />

          <TextFieldOutlinedInput
            margin="normal"
            required
            fullWidth
            id="ReenterNewPassword"
            label="Re-enter new password"
            name="ReenterNewPassword"
            autoComplete="off"
            placeHolderText={"Re-enter new password"}
            onTextChange={onEnterConfiremNewPassword}
            sx={{
              mt: 7,
              mb: 2,
              width: "12.5rem",
              height: "2.5rem",
              borderRadius: 10,
              boxShadow: 0,
            }}
          />
          {confirmNewPassword.value ? (
            ""
          ) : (
            <p className="error-message">{message}</p>
          )}

          <Button
            disabled={!isFilledNewPassword || !isFilledConfirmNewPassword}
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 7,
              mb: 2,
              width: "12.5rem",
              height: "2.5rem",
              borderRadius: 10,
              boxShadow: 0,
              bgcolor: "#1e86f0",
              "&:hover": {
                boxShadow: 0,
                bgcolor: "#4da6ff",
              },
              "&:disabled": {
                backgroundColor: "#cde1f4",
                color: "#ffffff",
              },
            }}
          >
            reset
          </Button>
        </Box>
      </Box>
      {showLoader && <Loader />}
    </Box>
  );
}
